import { Sans } from "../../Components/Typography"
import { Flex } from "../../Components/Flex"
import React, { useEffect, useState } from "react"
import NextLink from "next/link"
import { Box } from "../Box"
import styled from "styled-components"
import { GillyIcon } from "../GillyIcon"
import { Media } from "../../Components/Responsive"

export const Nav: React.FC<{
  projects: any
  maxWidth?: number
  router: any
  isFullBleed?: boolean
  isMobileFullBleed?: boolean
  aboutText: string
  activeProject?: any
}> = ({ projects, maxWidth, router, aboutText, activeProject, isFullBleed, isMobileFullBleed }) => {
  const [showProjects, setShowProjects] = useState(false)
  const [showAbout, setShowAbout] = useState(false)
  const [showDescription, setShowDescription] = useState(false)
  const activeSlug = router.query.p
  const activeName = activeProject?.name
  const projectDescription = activeProject?.projectDescription

  const showSpace = projectDescription?.charAt(0) === "’" ? "" : " "

  const onClickProjects = () => {
    console.log("1")
    setShowProjects(!showProjects)
    setShowAbout(false)
  }

  const onClickAbout = () => {
    setShowProjects(false)
    setShowAbout(!showAbout)
  }

  useEffect(() => {
    if (showProjects) {
      setShowProjects(false)
    }
  }, [activeSlug])

  const email = "claregillen@gmail.com"

  const MobileNav = () => {
    return (
      <Box
        style={{
          top: 0,
          left: 0,
          position: "fixed",
          width: "100%",
          flexDirection: "column",
          zIndex: 50,
          display: "flex",
          maxHeight: "100vh",
          maxWidth: maxWidth ? maxWidth + "px" : "none",
          background: (showProjects || showAbout || showDescription) && !isMobileFullBleed ? "white" : "transparent",
          boxShadow:
            (showProjects || showAbout || showDescription) && !isFullBleed ? "0 0 0.8rem 0.8rem #ffffff" : "none",
        }}
      >
        <Flex flexDirection="row" justifyContent="space-between" width="100%" px={2} pt="17px">
          <Sans size="0" style={{ cursor: "pointer" }}>
            <NavSpan active={showProjects} onClick={onClickProjects}>
              Projects
            </NavSpan>
            ,{" "}
            <NavSpan active={showAbout} onClick={onClickAbout}>
              Info
            </NavSpan>
          </Sans>
          <NextLink href="/">
            <IconWrapper pt="6px" pr="2px">
              <GillyIcon height="18px" />
            </IconWrapper>
          </NextLink>
        </Flex>
        <Box px={2} style={{ flex: 1, overflowY: "auto", zIndex: 3 }}>
          {showProjects && (
            <Flex>
              <Sans size="0" style={{ cursor: "pointer", display: "inline" }}>
                {projects?.map((project, index) => {
                  return (
                    <NextLink href={{ pathname: "/project", query: { p: project?.slug?.current } }} key={project._id}>
                      <span>
                        <NavSpan>{project.name}</NavSpan>
                        <span>{projects.length > 1 && index !== projects.length - 1 ? `,${" "}` : ""}</span>
                      </span>
                    </NextLink>
                  )
                })}
              </Sans>
            </Flex>
          )}
          {!showProjects && !showAbout && !!activeSlug && (
            <ProjectWrapper
              style={{ zIndex: 3 }}
              flexDirection="column"
              onClick={() => {
                if (!!projectDescription) {
                  setShowDescription(!showDescription)
                }
              }}
            >
              <Sans size="0">{showDescription ? `${activeName}${showSpace}${projectDescription}` : activeName}</Sans>
            </ProjectWrapper>
          )}
          {showAbout && (
            <Flex flexDirection="column" style={{ zIndex: 3 }}>
              <Flex>
                <Sans size="0">
                  <span>{aboutText}</span>
                </Sans>
              </Flex>
              <Flex flexDirection="column" style={{ zIndex: 3 }}>
                <Box>
                  <Sans size="0">
                    <a
                      href="https://www.instagram.com/gilly_studio"
                      style={{ textDecoration: "none", color: "inherit", cursor: "ne-resize" }}
                    >
                      <NavSpan>@gilly_studio</NavSpan>
                    </a>
                  </Sans>
                </Box>
                <Box>
                  <Sans size="0">
                    <a
                      href={`mailto:${email}`}
                      style={{ textDecoration: "none", color: "inherit", cursor: "ne-resize" }}
                    >
                      <NavSpan>{email}</NavSpan>
                    </a>
                  </Sans>
                </Box>
              </Flex>
            </Flex>
          )}
        </Box>
      </Box>
    )
  }

  const DesktopNav = () => {
    return (
      <Box
        style={{
          top: 0,
          left: 0,
          position: "fixed",
          width: "100%",
          flexDirection: "column",
          zIndex: 50,
          display: "flex",
          maxHeight: "100vh",
          maxWidth: maxWidth ? maxWidth + "px" : "none",
          background: (showProjects || showAbout || showDescription) && !isFullBleed ? "white" : "transparent",
          boxShadow:
            (showProjects || showAbout || showDescription) && !isFullBleed ? "0 0 0.8rem 0.8rem #ffffff" : "none",
          paddingBottom: "10px",
        }}
      >
        <Flex flexDirection="row" justifyContent="space-between" width="100%" px={2} pt={1}>
          <Sans size="1" style={{ cursor: "pointer" }}>
            <NavSpan active={showProjects} onClick={onClickProjects}>
              Projects
            </NavSpan>
            ,{" "}
            <NavSpan active={showAbout} onClick={onClickAbout}>
              Info
            </NavSpan>
          </Sans>
          <NextLink href="/">
            <IconWrapper pt="10px" pr="2px">
              <GillyIcon height="24px" />
            </IconWrapper>
          </NextLink>
        </Flex>
        <Box px={2} style={{ flex: 1, overflowY: "auto", position: "relative", zIndex: 2 }}>
          {showProjects && (
            <Box>
              <Sans size="1" style={{ cursor: "pointer", display: "inline" }}>
                {projects?.map((project, index) => {
                  return (
                    <NextLink href={{ pathname: "/project", query: { p: project?.slug?.current } }} key={project._id}>
                      <span>
                        <NavSpan>{project.name}</NavSpan>
                        <span>{projects.length > 1 && index !== projects.length - 1 ? `,${" "}` : ""}</span>
                      </span>
                    </NextLink>
                  )
                })}
              </Sans>
            </Box>
          )}
          {!showProjects && !showAbout && activeSlug && (
            <ProjectWrapper
              flexDirection="column"
              onMouseEnter={() => {
                if (!!projectDescription) {
                  setShowDescription(true)
                }
              }}
              onMouseLeave={() => {
                if (!!projectDescription) {
                  setShowDescription(false)
                }
              }}
            >
              <Sans size="1">{showDescription ? `${activeName}${showSpace}${projectDescription}` : activeName}</Sans>
            </ProjectWrapper>
          )}
          {showAbout && (
            <Flex flexDirection="column">
              <Flex>
                <Sans size="1">
                  <span>{aboutText}</span>
                </Sans>
              </Flex>
              <Flex>
                <Sans size="1">
                  <a
                    href="https://www.instagram.com/gilly_studio"
                    style={{ textDecoration: "none", color: "inherit", cursor: "ne-resize" }}
                  >
                    <NavSpan>@gilly_studio </NavSpan>
                  </a>
                  <a href={`mailto:${email}`} style={{ textDecoration: "none", color: "inherit", cursor: "ne-resize" }}>
                    <NavSpan>{email}</NavSpan>
                  </a>
                </Sans>
              </Flex>
            </Flex>
          )}
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Media greaterThan="sm">
        <DesktopNav />
      </Media>
      <Media lessThan="md">
        <MobileNav />
      </Media>
    </>
  )
}

const NavSpan = styled("span")<{ active?: boolean }>`
  color: ${(p) => (p.active ? "#b07ad4" : "black")};
  text-decoration: none;
  font-size: inherit;
  &:hover {
    color: #b07ad4;
  }
`

const IconWrapper = styled(Box)`
  cursor: pointer;
  &:hover {
    path {
      fill: #b07ad4;
    }
  }
`

const ProjectWrapper = styled(Flex)`
  cursor: pointer;
  .description {
    display: none;
  }
  &:hover {
    p {
      display: block !important;
    }
  }
`
