import { Theme } from "../lib/theme"
import { Flex } from "./Flex"
import { Nav } from "./Nav"

export const Layout: React.FC<{
  children: any
  projects: any
  maxWidth?: number
  router: any
  aboutText: string
  activeProject?: any
  isFullBleed?: boolean
  isMobileFullBleed?: boolean
}> = ({ isFullBleed, children, projects, maxWidth, router, aboutText, activeProject, isMobileFullBleed }) => {
  return (
    <Theme>
      <Flex
        flexDirection="column"
        style={{ flex: 1, position: "absolute", top: 0, left: 0, maxWidth: maxWidth ? maxWidth + "px" : "auto" }}
        justifyContent="space-between"
        height="100%"
        width="100%"
      >
        <Nav
          isFullBleed={isFullBleed}
          isMobileFullBleed={isMobileFullBleed}
          activeProject={activeProject}
          projects={projects}
          maxWidth={maxWidth}
          router={router}
          aboutText={aboutText}
        />
        {children}
      </Flex>
    </Theme>
  )
}
