import React from "react"
import { ThemeProvider } from "styled-components"

export const breakpoints = {
  /** Above 1192 */
  xl: 1200,
  /** Between 1024 and  1191 */
  lg: 992,
  /** Between 900 and 1023 */
  md: 768,
  /** Between 768 and  899 */
  sm: 576,
  /** Below 767 */
  xs: 575,
}

export const themeProps = {
  colors: {
    gray: "#797979",
    blue: "#06119F",
  },
  fontFamily: {
    sans: {
      regular: "'unica77LL-regular', sans-serif",
    },
  },
  space: {
    0.5: 4,
    /** Equivalent to 8px  */
    1: 10,
    /** Equivalent to 16px  */
    2: 20,
    /** Equivalent to 24px  */
    3: 24,
    /** Equivalent to 32px  */
    4: 32,
    /** Equivalent to 40px  */
    5: 40,
    /** Equivalent to 48px  */
    6: 48,
    /** Equivalent to 56px  */
    7: 56,
    /** Equivalent to 64px  */
    8: 64,
    /** Equivalent to 72px  */
    9: 72,
    10: 80,
    /** Equivalent to 72px  */
    11: 88,
    12: 96,
    13: 104,
    14: 112,
    15: 120,
  },

  typeSizes: {
    /** Unica  */
    sans: {
      "3": {
        fontSize: 12,
        lineHeight: 16,
      },
      /** Equivalent to 10px size / 14px line-height  */
      "0": {
        fontSize: 24,
        lineHeight: 30,
      },
      "1": {
        fontSize: 30,
        lineHeight: 40,
      },
      "2": {
        fontSize: 120,
        lineHeight: 130,
      },
    },
  },

  radii: {
    mainButton: 40,
  },

  mediaQueries: {
    xl: `(min-width: ${breakpoints.xl}px)`,
    lg: `(min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xl - 1}px)`,
    md: `(min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg - 1}px)`,
    sm: `(min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md - 1}px)`,
    xs: `(max-width: ${breakpoints.sm - 1}px)`,
    /** Determines if the input device has the notion of hover, e.g. mouse. */
    hover: `not all and (pointer: coarse), not all and (-moz-touch-enabled: 1)`,
  },

  // https://github.com/dragma/styled-bootstrap-grid#styled-bootstrap-grid
  grid: {
    gridColumns: 16,
    breakpoints,
    container: {
      padding: 0,
      maxWidth: {
        xl: 40000,
        lg: 40000,
        md: 40000,
        sm: 40000,
        xs: 40000,
      },
    },
    row: {
      padding: 0,
    },
    col: {
      padding: 0,
    },
  },
}

/**
 * Creates a new Grid context for web. On React Native it serves as a noop.
 */
const GridThemeProvider = ({ children }) => {
  const StyledGrid = require("styled-bootstrap-grid")
  return <StyledGrid.GridThemeProvider gridTheme={themeProps.grid}>{children}</StyledGrid.GridThemeProvider>
}

export const Theme = (props) => {
  return (
    <ThemeProvider theme={themeProps}>
      <GridThemeProvider>{props.children}</GridThemeProvider>
    </ThemeProvider>
  )
}

/** All available px spacing maps */
export type SpacingUnit = keyof typeof themeProps["space"]
/** All available color keys */
export type Color = keyof typeof themeProps["colors"]
/** All available width breakpoint */
export type Breakpoint = keyof typeof breakpoints

/** All available type sizes */
export type TypeSizes = typeof themeProps.typeSizes
/** All available sizes for our serif font */
export type SansSize = keyof TypeSizes["sans"] | Array<keyof TypeSizes["sans"]>
/** All available sizes for our display font */
