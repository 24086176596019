import * as React from "react"

export const GillyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1125.45 369.57"
      height={props.height}
      {...props}
    >
      <path
        d="M918.72 238.78c50 0 91-27 91-72 0-40-24-59-78-69-16-3-22-11-21-20 1-10 9-17 21-17 79 0 132 57 132 124s-63 125.5-135 125.5c-51 0-68-5.5-97-15.5-35-13-47-12-59 25-12 31 7 46 63 46h98c124 0 191-102 191-181 0-124-86-184-187-184-51 0-79 35-79 68 0 40 33 59 73 65 20 3 31 14 31 29 0 17-12 30-34 30-66 0-125-31-125-123 0-43 26-66-21-66-44 0-44 24-44 66 0 96 64 169 180 169zm-281-3c0 13 9 29 28 28h63c22 0 32-4 37-19 19-51-7-45-42-30-24 10-36 1-35-23 2-35 3-84 5-122 3-55 10-66-28-66-48 0-31 16-28 64s0 168 0 168zm-104 101c0 17 15 29 29 29h131c28 0 33-4 45-40 19-60-44-27-92-18-21 4-55 13-55-36v-239c0-17-15-29-29-29h-131c-28 0-33 4-45 40-19 60 44 27 92 18 21-4 55-13 55 36zm-327-206c-50 0-91 27-91 72 0 40 24 59 78 69 16 3 22 11 21 20-1 10-9 17-21 17-79 0-132-57-132-124s63-125.5 135-125.5c51 0 68 5.5 97 15.5 35 13 47 12 59-25 12-31-7-46-63-46h-98c-124 0-191 102-191 181 0 124 86 184 187 184 51 0 79-35 79-68 0-40-33-59-73-65-20-3-31-14-31-29 0-17 12-30 34-30 66 0 125 31 125 123 0 43-26 66 21 66 44 0 44-24 44-66 0-96-64-169-180-169zm281 3c0-13-9-29-28-28h-63c-22 0-32 4-37 19-19 51 7 45 42 30 24-10 36-1 35 23-2 35-3 84-5 122-3 55-10 66 28 66 48 0 31-16 28-64s0-168 0-168z"
        fill="#000"
      />
    </svg>
  )
}
